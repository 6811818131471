.privacy-policy-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .privacy-policy-container h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #000;
  }
  
  .privacy-policy-container h2 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    color: #444;
  }
  
  .privacy-policy-container p {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .privacy-policy-container ul {
    margin: 1rem 0;
    padding-left: 1.5rem;
  }
  
  .privacy-policy-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-policy-container a:hover {
    text-decoration: underline;
  }